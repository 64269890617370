import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { api } from '.';
import { Genre } from 'web/store/genres/types';

export const useGenres = () => {
  return useQuery([queryKeys.genres.index], async () => {
    const response = await api.get<Genre[]>('/genres');
    return response.data;
  });
};

export const useGenresByIds = (ids: string[]) => {
  return useQuery(
    [queryKeys.genres.favorites, ids.join(' ')],
    async () => {
      const response = await api.get<Genre[]>('/genres', { params: { ids } });
      return response.data;
    },
    {
      enabled: ids.length > 0,
      staleTime: Infinity,
    },
  );
};
