import { useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

import { signInWithToken, useSession } from 'web/api/session';
import { IS_E2E_TEST } from './general';
import { ValueOf } from './types';
import { showToast } from './toast';
import { withAsyncErrorHandling } from 'web/api';
import displayFirebaseError from './auth/displayFirebaseError';
import { reactQueryClient } from 'shared/components/Providers/ThreadableQueryClientProvider';
import { queryKeys } from 'web/api/queryKeys';
import { useDispatch } from 'react-redux';
import { setFirebaseUser, useFirebaseUserState } from 'web/store/firebase/slice';
import useLoginUserFromIdToken from './useLoginUserFromIdToken';
import * as GA from 'web/utils/analytics/google-analytics';

export enum AuthMethod {
  GOOGLE = 'google',
  APPLE = 'apple',
  SIGN_IN_EMAIL_AND_PASSWORD = 'sign-in-email-and-password',
  SIGN_UP_EMAIL_AND_PASSWORD = 'sign-up-email-and-password',
}

const signInWithGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithPopup(provider);
};

const signInWithApple = async () => {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  firebase.auth().signInWithPopup(provider);
};

export const sendEmailVerification = async (firebaseUser: firebase.User) => {
  try {
    await firebaseUser.sendEmailVerification();
    showToast({ message: 'Verification email sent.', type: 'success' });
  } catch (err) {
    displayFirebaseError(err);
  }
};

export type ExistingUserLoginData = {
  email: string;
  password: string;
};

export type NewUserLoginData = ExistingUserLoginData & {
  displayName: string;
};

const signInWithEmailAndPassword = async (data: ExistingUserLoginData) => {
  // check if email already exists
  try {
    await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
  } catch (err) {
    console.log(err);
    displayFirebaseError(err);
  }
};

const signUpWithEmailAndPassword = async (data: NewUserLoginData) => {
  try {
    const credentials = await firebase
      .auth()
      .createUserWithEmailAndPassword(data.email, data.password); // automatically signs in

    if (credentials.user) {
      await credentials.user.updateProfile({ displayName: data.displayName });
      await credentials.user.sendEmailVerification();
    } else {
      return false;
    }
    return true;
  } catch (err) {
    displayFirebaseError(err);
    return false;
  }
};

export type SignInArgs = {
  authMethod: ValueOf<AuthMethod>;
  data?: ExistingUserLoginData | NewUserLoginData;
};

export const signIn = async ({ authMethod, data }: SignInArgs) => {
  switch (authMethod) {
    case AuthMethod.GOOGLE:
      return signInWithGoogle();
    case AuthMethod.APPLE:
      return signInWithApple();
    case AuthMethod.SIGN_IN_EMAIL_AND_PASSWORD:
      return signInWithEmailAndPassword(data as ExistingUserLoginData);
    case AuthMethod.SIGN_UP_EMAIL_AND_PASSWORD:
      return signUpWithEmailAndPassword(data as NewUserLoginData);
    default:
      throw new Error(`Unknown authMethod: ${authMethod}`);
  }
};

export const signOut = async () => firebase.auth().signOut();

export async function getToken(forceRefresh = false) {
  const idTokenResult = await firebase.auth().currentUser?.getIdTokenResult(forceRefresh);
  return idTokenResult?.token;
}

export const showMustVerifyEmailToast = (firebaseUser: firebase.User) => {
  showToast({
    message: 'Please check your email and click the verify link before continuing.',
    cta: {
      text: 'Resend verification email',
      onClick: () => sendEmailVerification(firebaseUser),
    },
    type: 'info',
  });
};

export default function useFirebase() {
  const dispatch = useDispatch();
  const { loginUserFromIdToken } = useLoginUserFromIdToken();
  const { data: sessionUser } = useSession();
  const { firebaseUser } = useFirebaseUserState();

  const setUserInStore = async () => {
    const token = await getToken();
    if (token) {
      withAsyncErrorHandling(async () => {
        const sessionUser = await signInWithToken(token);

        if (sessionUser.is_signup) {
          GA.setAnalyticsUser(sessionUser.id);

          // not sure a delays is needed here, but doing it just
          // to be safe, to make sure the user is set, before firing signup event
          setTimeout(() => {
            GA.Actions.session.userSignedUp();
          }, 3000);
        }

        localStorage.setItem('userId', sessionUser.id);
        reactQueryClient.setQueryData([queryKeys.session], sessionUser);
      });
    }
  };

  useEffect(() => {
    if (firebaseUser && !sessionUser) {
      if (firebaseUser.emailVerified) {
        setUserInStore();
      } else {
        showMustVerifyEmailToast(firebaseUser);
        firebase.auth().signOut();
      }
    }
  }, [firebaseUser, sessionUser]);

  useEffect(() => {
    // Use emulator and test config if running Cypress tests
    // Emulate Auth: https://firebase.google.com/docs/emulator-suite/connect_auth#web-namespaced-api
    if (IS_E2E_TEST) {
      firebase.auth().useEmulator('http://localhost:9099/');
    }

    loginUserFromIdToken();

    const unsub = firebase.auth().onAuthStateChanged(userFromFirebase => {
      if (userFromFirebase) {
        const cleanedFirebaseUser = JSON.parse(JSON.stringify(userFromFirebase));
        dispatch(setFirebaseUser(cleanedFirebaseUser));
      } else {
        reactQueryClient.invalidateQueries([queryKeys.session]);
        GA.Actions.session.userSignedOut();
      }
    });

    return unsub;
  }, []);
}
